<template>
    <div class="mianbox">
        <div class="searchbox">
            <el-form ref="form" :model="form" label-width="80px" class="searchbox_left">
                <div class="leftbox">
                    <div class="left_one">
                        <el-input
                            placeholder="请输入项目名称或项目编号查询"
                            v-model="form.name"
                            size="small">
                            <i slot="prefix" class="el-input__icon el-icon-search"></i>
                        </el-input>
                    </div>
                    <div class="left_three">
                        <el-button type="primary" size="small" plain  @click="onSubmit">查询</el-button>
                    </div> 
                </div>
            </el-form>
            <div class="rightbox">
                <el-button type="primary" size="small" @click="derive()">导出资产包信息</el-button>
                <el-button type="primary" size="small" @click="newbuild(1)">新增现有资产包</el-button>
                <el-button type="primary" size="small" @click="newbuild(2)">补录存量资产包</el-button>
            </div> 
        </div>
        <!--新建资产包弹窗-->
        <el-dialog :title="dialogNamePro" :visible.sync="dialogFormVisible" width="80%" top='6vh' :before-close="AssethandleClose">
            <el-form ref="newform" :model="newform" label-width="100px" :rules="rules" label-position="top"  >
                <el-row  class="hangbox" :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="项目名称" prop="Name">
                            <el-input v-model="newform.Name" placeholder="请填写项目名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="出包方" prop="SellCompany" >
                            <el-autocomplete
                                style="width:100%"
                                v-model="newform.SellCompany"
                                :fetch-suggestions="querySearch"
                                value-key=Name
                                placeholder="请输入出包方"
                                @select="handleSelect"
                            ></el-autocomplete>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" >
                        <el-form-item label="经办部门" prop="department">
                            <el-cascader
                                placeholder="请选择上级部门"
                                :options="departmentlist"
                                v-model="newform.department"
                                clearable
                                filterable
                                style="width:100%"
                                :props="{ expandTrigger: 'hover' }"
                                @change="getuserlist(newform.department)"
                            >
                            </el-cascader>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="hangbox" :gutter="20">
                    <el-col :span="8" >
                        <el-form-item label="主经办人" prop="PrincipalId">
                            <el-select 
                                v-model="newform.PrincipalId" 
                                filterable 
                                placeholder="请选择主经办人" 
                                style="width:100%">
                                <el-option
                                    v-for="item in operator_alist"
                                    :key="item.uID"
                                    :label="item.uRealName"
                                    :value="item.uID">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" >
                        <el-form-item label="协办人员" prop="AssistantsArr" >
                            <el-select 
                                v-model="newform.AssistantsArr" 
                                multiple
                                filterable 
                                allow-create
                                default-first-option
                                placeholder="请选择协办人员" 
                                style="width:100%">
                                <el-option
                                    v-for="item in operator_blist"
                                    :key="item.uID"
                                    :label="item.uRealName"
                                    :value="item.uID">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
              
                </el-row>
                <el-row class="hangbox" :gutter="20">
                    <el-col :span="8" >
                        <el-form-item label="本金（元）" prop="Cost">
                            <div @mouseover="ShowPriceTxet(1)">
                            <el-tooltip class="item" effect="dark" placement="top">
                                <div slot="content" >{{costtip}}<br/>{{costtip2}}</div>
                                    <el-input v-model="newform.Cost" placeholder="请输入本金" type="number"
                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                @input="AdScale(1)"></el-input>
                            </el-tooltip>      
                        </div>              
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" >
                        <el-form-item label="利息（元）" prop="Interest" >
                            <div @mouseover="ShowPriceTxet(2)">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content">{{costtip}}<br/>{{costtip2}}</div>
                                    <el-input v-model="newform.Interest" placeholder="请输入利息" type="number"
                                    oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                    @input="AdScale(2)"></el-input>
                                </el-tooltip>
                            </div>  
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" >
                        <el-form-item label="其它（元）" prop="DefaultInterest">
                            <div @mouseover="ShowPriceTxet(3)">
                                <el-tooltip class="item" effect="dark" content="罚息、垫付费用、诉讼等其它费用" placement="bottom">
                                    <el-tooltip class="item" effect="dark" placement="top">
                                        <div slot="content">{{costtip}}<br/>{{costtip2}}</div>
                                        <el-input v-model="newform.DefaultInterest" placeholder="请输入罚息" type="number"
                                        oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                        @input="AdScale(3)"></el-input>
                                    </el-tooltip>
                                </el-tooltip>
                            </div>
                        </el-form-item>
                    </el-col>              
                </el-row>
                <el-row class="hangbox" :gutter="20">
                    <el-col :span="8" >
                        <el-form-item label="抵债金额（元）" prop="PledgeAssetPrice">
                            <div @mouseover="ShowPriceTxet(4)">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content">{{costtip}}<br/>{{costtip2}}</div>
                                    <el-input v-model="newform.PledgeAssetPrice" placeholder="请输入抵债金额" type="number"
                                    oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                    @input="AdScale(4)"></el-input>
                                </el-tooltip>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" >
                        <el-form-item label="总规模（元）" prop="Scale">
                            <el-input v-model="newform.Scale" placeholder="请输入总规模" type="number"
                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                            @input="UpScale(newform.Scale)"></el-input> 
                        </el-form-item>
                    </el-col> 
                </el-row>              
                <el-row class="hangbox" :gutter="20">
                    <el-col :span="24" >
                        <el-form-item label="中介机构" prop="CompanysArr" >
                            <el-select 
                                v-model="newform.CompanysArr" 
                                multiple
                                filterable 
                                allow-create
                                default-first-option
                                placeholder="请选择中介机构" 
                                style="width:100%">
                                <el-option
                                    v-for="item in agencylist"
                                    :key="item.Id"
                                    :label="item.Name"
                                    :value="item.Id">
                                </el-option>
                            </el-select> 
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="hangbox">
                    <el-col :span="24" >
                        <el-form-item label="其他说明" prop="Remark">
                            <el-input v-model="newform.Remark" type="textarea" placeholder="如有其他说明请填写"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel()">取 消</el-button>
                <el-button type="primary" @click="determine('newform')">确 定</el-button>
            </div>
        </el-dialog>
         <!--正常、中止状态说明-->
         <el-dialog title="修改状态" :visible.sync="dialogFormVisible1" width="40%" top='6vh'>
            <el-form ref="newform1" :model="newform1" label-width="100px" :rules="rules" label-position="top"  >
                <el-row class="hangbox">
                    <el-col :span="24" >
                        将状态改为：{{staname}}
                    </el-col>
                    <el-col :span="24" >
                        <el-form-item label="修改原因" prop="Remark">
                            <el-input v-model="newform1.Remark" type="textarea" placeholder="请填写修改原因"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel1()">取 消</el-button>
                <el-button type="primary" @click="determine1('newform1')">确 定</el-button>
            </div>
        </el-dialog>
        <!--资产包列表-->
        <div class="Tablebox">
            <el-table
            :data="tableData"
            border
            @selection-change="handleSelectionChange"
            :style="{width: '100%','margin-bottom':'2rem'}"
            :header-cell-style="{background:'#f5f7fa'}">
                <el-table-column v-for="(item, index) in tableList" :key="index" :prop="item.prop" :label="item.label" :type="item.type">
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="120">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                        <el-button type="text" size="small" @click="handleDelete(scope.$index, scope.row)" slot="reference">删除</el-button>
                        <el-button type="text" size="small" @click="UpdateStatus(scope.row)" >{{scope.row.Status==1?"正常":"终止"}}</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="paging.currentPage"
                :page-sizes="[10]"
                :page-size="paging.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="paging.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    import { instance,addSysOperateLog,getAssetProjectPage,getDepartmentUser,addAssetPro,GetAllUserList,BaseApiUrl} from "../../../apis/apis.js";
    import { changeToChinese } from '../../../../util/helps';
    import routes from "../../../router/index"; 

    export default { 
        data(){
            return{
                restaurants: [],
                rules: {
                    Name: [
                        { required: true, message: '请输入项目名称', trigger: 'blur' },
                    ],
                    SellCompany: [
                        { required: true, message: '请输入出包方',},
                    ],
                    department: [
                        { required: true, message: '请选择经办部门', trigger: 'blur' },
                    ],
                    PrincipalId: [
                        { required: true, message: '请选择主经办人', trigger: 'blur' },
                    ],
                    AssistantsArr: [
                        { required: true, message: '请选择协办人员', trigger: 'blur' },
                    ],
                    Scale: [
                        { required: true, message: '请输入总规模', trigger: 'blur' }, 
                    ],
                    Cost: [
                        { required: true, message: '请输入本金', trigger: 'blur' },  
                    ],
                    Interest: [
                        { required: true, message: '请输入利息', trigger: 'blur' }, 
                    ],
                    DefaultInterest: [
                        { required: true, message: '请输入罚息', trigger: 'blur' }, 
                    ],
                    PledgeAssetPrice: [
                        { required: true, message: '请输入抵债金额', trigger: 'blur' }, 
                    ],
                    BidPrice: [
                        { required: false, message: '请输入购入价', trigger: 'blur' }, 
                    ],
                    ReducedPrice: [
                        { required: false, message: '请输入处置价', trigger: 'blur' }, 
                    ], 
                    CompanysArr: [
                        { required: true, message: '请选择中介机构', trigger: 'blur' },
                    ],

                },
                dialogNamePro:"新增现有资产包",
                dialogFormVisible: false,
                newform: {
                    Name: '',//项目名称
                    SellCompany:'',//出包方
                    SellCompanyId:'',//出包方的ID
                    department:[],//经办部门
                    DepartmentId:'',//经办部门的id
                    PrincipalId:[],//主经办人
                    AssistantsArr:[],//协办人员
                    Scale:0,//总规模
                    Cost:0,//本金
                    Interest:0,//利息
                    DefaultInterest:0,  
                    PledgeAssetPrice:0,//抵债金额
                    CompanysArr:[],//中介机构
                    Remark:'',//其他说明
                    IsDeleted: false,
                    BidPrice:'0',//购置价格
                    ReducedPrice:'0',//处置价格
                    DataType:0,
                    BuyAssessPrice:0//买入评估价
                },
                dialogFormVisible1:false, 
                newform1: {
                    Id:0,
                    Status:0,
                    Remark:'', //说明
                },
                //经办部门列表
                departmentlist:[],
                //主经办人列表
                operator_alist:[],
                //协办人员列表
                operator_blist:[], 
                //中介机构列表
                agencylist:[],
                 //列表展示的值
                tableList:[
                    {  
                        type:"selection"
                    },
                    {
                        prop:'Code',
                        label:'系统编号'
                    },
                    {
                        prop:'Name',
                        label:'项目名称'
                    },
                    {
                        prop:'DataType',
                        label:'类型'
                    },
                    {
                        prop:'Scale',
                        label:'总规模（元）'
                    },
                    {
                        prop:'PrincipalName',
                        label:'经办人'
                    },
                    {
                        prop:'LiquidateValue',
                        label:'清算价值(万元)'
                    },
                    {
                        prop:'BidPrice',
                        label:'购入价（元）'
                    },
                    {
                        prop:'ReducedPrice',
                        label:'处置价（元）'
                    },
                    {
                        prop:'CompanysName',
                        label:'中介机构'
                    },
                   
                ],
                //级联选择器
                options: [ 
                ],
                //搜索关键词
                form: {
                    name: '',
                    //handled:[],
                },
                //表单数据
                tableData: [],
                //分页
                paging:{
                    currentPage:1,//当前加载页
                    size:10,//每页显示条目个数
                    total:0,//总条目数
                },
                staname:'终止', 
                costtip:'0',
                costtip2:'',
                ids:[],
            }
        },
        created(){
            if(this.$route.params.id==1){
                this.newbuild(1)
            }else if(this.$route.params.id==2){
                this.newbuild(2)
            }
        },
        mounted() {
            this.getpackagelist();//获取资产包列表
            this.getorganlist(); //获取机构出包方列表 
            this.getdepartmentlist();//获取部门列表 
        },
        computed: {
            ...mapState({ // 从 state 中的到的计算属性
                catch_components: state => state.catch_components,  // keepalive缓存
                tabList: state => state.tabList,  // tags菜单列表
                active: state => state.active, // 已选中菜单
            })
        },
        methods: {
            derive(){
                if(this.ids.length<=0){
                    this.$message({
                        message: '至少勾选一列数据',
                        type: 'error'
                    });
                }else{
                    var time = new Date();
                    var nids=this.ids.join(",");
                    var text="资产包导出信息"+time.toLocaleString()+".xlsx";
                    instance.get('/AssetProject/DownExecelPro?ids='+nids,{params: {}, responseType: 'blob'}).then(res=>{
                        const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                        const objectUrl = URL.createObjectURL(blob) // 创建URL
                        const link = document.createElement('a');
                        link.href = objectUrl;
                        // 这里是获取后台传过来的文件名
                        link.setAttribute('download',text)
                        link.click() // 下载文件
                        
                    });
                }
            },
            handleSelectionChange(rows){
                this.ids=[];
                rows.forEach(res=>{
                    this.ids.push(res.Id);
                });                    
            },
            UpScale(value){  
               if(value>0){
                this.newform.Cost = 0;
                this.newform.Interest = 0;
                this.newform.DefaultInterest = 0;
                this.newform.PledgeAssetPrice=0;
               }
            },
            AdScale(index){ 
                var v = Number(this.newform.Cost) + Number(this.newform.Interest) +Number(this.newform.DefaultInterest)+Number(this.newform.PledgeAssetPrice); 
                if(v>0){
                    this.newform.Scale=v
                    console.log(v.toString().match(/^\d+(?:\.\d{0,2})?/)[0])
                    this.newform.Scale=v.toString().match(/^\d+(?:\.\d{0,2})?/)[0];
                } 
                switch(index){
                    case 1: 
                        if(this.newform.Cost>0){ 
                            this.costtip =this.newform.Cost.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.Cost)
                        } 
                        break;
                    case 2: 
                        if(this.newform.Interest>0){ 
                            this.costtip =this.newform.Interest.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.Interest)
                        } 
                        break;
                    case 3: 
                        if(this.newform.DefaultInterest>0){ 
                            this.costtip =this.newform.DefaultInterest.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.DefaultInterest)
                        } 
                        break;    
                    case 4: 
                        if(this.newform.PledgeAssetPrice>0){ 
                            this.costtip =this.newform.PledgeAssetPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.PledgeAssetPrice)
                        } 
                        break;      
                }                     
            },
            ShowPriceTxet(index){
                switch(index){
                    case 1: 
                        if(this.newform.Cost>0){ 
                            this.costtip =this.newform.Cost.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.Cost)
                        }else{
                            this.costtip='0';  
                            this.costtip2='';
                        }
                        break;
                    case 2: 
                        if(this.newform.Interest>0){ 
                            this.costtip =this.newform.Interest.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.Interest)
                        }else{
                            this.costtip='0';  
                            this.costtip2='';
                        }
                        break;
                    case 3: 
                        if(this.newform.DefaultInterest>0){ 
                            this.costtip =this.newform.DefaultInterest.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.DefaultInterest)
                        }else{
                            this.costtip='0';  
                            this.costtip2='';
                        } 
                        break;    
                    case 4: 
                        if(this.newform.PledgeAssetPrice>0){ 
                            this.costtip =this.newform.PledgeAssetPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.PledgeAssetPrice)
                        }else{
                            this.costtip='0';  
                            this.costtip2='';
                        } 
                        break;      
                }  
            },
            UpdateStatus(item){ 
                this.staname= item.Status==1?"正常":"终止";
                this.dialogFormVisible1=true;
                this.newform1.Id=item.Id
                if(item.Status==0){
                    this.newform1.Status=1
                }else{
                    this.newform1.Status=0
                }
            },
            //出包方
            handleSelect(item) {
                this.newform.SellCompanyId=item.Id
                //console.log(this.newform);
            },
            querySearch(queryString, cb) {
                var restaurants = this.restaurants;
                var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results);
            },
            createFilter(queryString) {
                return (restaurant) => {
                     return (restaurant.Name.indexOf(queryString.toLowerCase()) === 0);
                };
            },
            //获取资产包列表
            getpackagelist(){ 
                var para={
                    page:this.paging.currentPage,
                    intPageSize:this.paging.size
                }; 
                getAssetProjectPage(para).then((res) => {
                    this.tableData=res.data.response.data;
                    this.tableData.forEach(item=>{
                        item.DataType = item.DataType==1?'新增':'存量';
                        if(item.Scale>=1000){
                            item.Scale= item.Scale.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        }
                        if(item.BidPrice>=1000){
                            item.BidPrice= item.BidPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        }
                        if(item.ReducedPrice>=1000){
                            item.ReducedPrice= item.ReducedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        }
                        if(item.Reckon>=1000){
                            item.Reckon= item.Reckon.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        }
                    }) 
                    this.paging.total=res.data.response.dataCount
                });
 
                 //操作日志
                  let param = { Name:routes.history.current.name+"-列表" };
                  addSysOperateLog(param);
            },
            //获取用户列表
            getuserlist(valve){ 
                var key = valve.slice(-1) 
                var para={
                    did:key[0]
                };
                getDepartmentUser(para).then((res) => { 
                    if(res.data.success){
                        this.operator_alist=res.data.response;
                        this.operator_blist=res.data.response;
                    }
                }); 
                // GetAllUserList().then((res) => { 
                //     if(res.data.success){
                //         this.operator_blist=res.data.response
                //     }
                // }); 
                var end = valve[valve.length-1]
                this.newform.DepartmentId=end
            },
            //获取机构出包方列表
            getorganlist(){
                instance.get('/Companys/Get?page=1&intPageSize=500').then(res=>{
                    if(res.data.success){
                        var cblist = [];
                        var jglist =[];
                        res.data.response.data.forEach(item=>{
                            if(item.TypeId==1){
                                cblist.push(item)
                            }
                            if(item.TypeId==2){
                                jglist.push(item)
                            }                       
                        })
                        this.agencylist=cblist
                        this.restaurants = jglist
                    }
                });
            },
            //获取出包方列表
            getSelllist(){
                instance.get('/Companys/Get?page=1&intPageSize=500&typeid=2').then(res=>{
                    if(res.data.success){
                        this.restaurants = res.data.response.data
                    }
                });
            },
            //获取部门列表
            getdepartmentlist(){
                instance.get('/Department/GetDepartmentTree').then(res=>{
                    if(res.data.success){ 
                        var delist = null;
                        this.departmentlist = null;
                        res.data.response.children.forEach(item=>{
                            if(item.label=='前台'){
                                delist=item
                            }
                        }) 
                        this.departmentlist =  [delist]
                    }
                });
            },
            //关闭资产详细页
            AssethandleClose(done) {
                var _this = this;
                this.$confirm('确认关闭？').then(_ => {  
                    if(_this.$route.params.id>0){
                        _this.$router.replace({
                            path: '/home/home',
                        });
                    }else{
                        _this.$router.go(0)  
                    }                                                                                                                                                                                                                                                                             
                        done();
                    })
                    .catch(_ => { });
            },
            //新建资产包
            newbuild(t){ 
                this.newform.DataType = t;
                if(t==1){
                    this.dialogNamePro ="新增现有资产包";
                }else{
                    this.dialogNamePro ="补录存量资产包";
                }
                this.dialogFormVisible=true
            },
            cancel(){
                this.dialogFormVisible=false
            },
            cancel1(){
                this.dialogFormVisible1=false
            },
            determine(newform){
                this.$refs[newform].validate((valid) => {
                if (valid) { 
                    addAssetPro(this.newform).then((res) => { 
                        if(res.data.success){
                            this.$message({
                                message: '创建成功',
                                type: 'success'
                            });
                            this.getpackagelist();//获取资产包列表
                            this.dialogFormVisible=false   
                            //操作日志
                           let param = {  ExternalId: res.data.response,Name:routes.history.current.name+"-新建资产包" };
                          addSysOperateLog(param);
                        }else{
                            this.$message.error('创建错误请联系管理员');
                        }
                        this.$refs[newform].resetFields();
                    });  
                } else {
                    this.$message.error('请正确填写信息');
                    }
                });
            },
            determine1(newform1){ 
                this.$refs[newform1].validate((valid) => {
                if (valid) {
                  instance.post('/AssetProject/GetProjectStatus',this.newform1).then(res=>{ 
                        if(res.data.success){
                            this.$message({
                                message: '提交成功',
                                type: 'success'
                            });
                            this.getpackagelist(); //获取资产包列表
                            this.dialogFormVisible1=false   
                            //操作日志
                           let param = {  ExternalId: res.data.response,Name:routes.history.current.name+(this.newform1.Status==0?"-正常":"-终止"),Remark: this.newform1.Remark };
                           addSysOperateLog(param);
                        }else{
                            this.$message.error('提交错误请联系管理员');
                        }
                        this.$refs[newform1].resetFields();
                    });
                } else {
                    this.$message.error('请正确填写信息');
                    }
                });
            },
            //查询按钮
            onSubmit() {
                instance.get('/AssetProject/Get?page='+this.paging.currentPage+'&key='+this.form.name+'&intPageSize='+this.paging.size).then(res=>{
                    //console.log(res.data)
                    this.tableData=res.data.response.data;
                    this.tableData.forEach(item=>{
                        item.DataType = item.DataType==1?'新增':'存量';
                    })
                    this.paging.total=res.data.response.dataCount
                });
                //console.log(this.form);
                  //操作日志
                let param = { Name:routes.history.current.name+"-查询" };
                addSysOperateLog(param);
            },
            //查看
            handleClick(row) {  
                var url = `/modules/promanage1/package/${row.Id}`
         
                this.$router.replace(url);
                // console.log(row.Code);
              
                this.$emit("settagsext", {
                    title: row.Code,
                    path: url, 
                    });
            

                   //操作日志
               let param = {  ExternalId:row.id,Name:routes.history.current.name+"-查看" };
               addSysOperateLog(param);
            },
            //删除
            handleDelete(index, row){
                var id = row.Id
                this.$confirm('您确定删除编号为：'+row.Code+'的项目吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                .then(() => { 
                    instance.delete('/AssetProject/Delete?id='+id).then(res=>{
                        if(res.data.success){
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.getpackagelist();
                            //操作日志
                            let param = {  ExternalId: res.data.response,Name:routes.history.current.name+"-删除" };
                           addSysOperateLog(param);
                        }else{
                            this.$message({
                            type: 'info',
                            message: res.data.msg
                        });          
                        }
                    });
                })
                .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });          
                });
                //console.log(index, row);
            },
            //分页
            //当前加载页 改变时会触发
            handleCurrentChange(val) {
                this.paging.currentPage=val
                //console.log(`当前页: ${val}`);
                this.getpackagelist();
            }
        }, 
    }
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
<style lang="less" scoped>
    .mianbox{
        padding: 1.5rem;
        background-color: #f6f8f9;
        height: 100%;
        box-sizing: border-box;
        .searchbox{
            padding: 2rem;
            background-color: #ffffff;
            box-shadow: 0 0 0.6rem #e0e0e0;
            border-radius:0.5rem ;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .searchbox_left{
                .leftbox{
                    display: flex;
                    align-items: center;
                    .left_one{
                        width: 26.8rem;
                    }
                    .left_two{
                        width: 28rem;
                        margin-left:2rem ;
                        .el-form-item{
                            margin-bottom: 0 !important;
                        }
                    }
                    .left_three{
                        margin-left:2rem ;
                    }
                }
            }
            // .rightbox{

            // }
        }
        
        .Tablebox{
            padding: 2rem;
            background-color: #ffffff;
            box-shadow: 0 0 0.6rem #e0e0e0;
            border-radius:0.5rem ;
            margin-top: 1.5rem;
        }
    }
</style>